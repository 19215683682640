<template>
    <div>
        <b-checkbox v-model="localfield" :name="data.FieldToSave" :native-value="data.NativeValue">
            {{ data.Label }}
        </b-checkbox>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object
        }
    },
    data () {
        return {
            localfield: ''
        }
    }
}
</script>
